<template>
  <v-container
    fluid
    class="engineer-detail detail-page white-background main-detail-page"
    v-if="getPermission('engineer:view')"
  >
    <div class="p-5 pb-0">
      <v-row>
        <v-col cols="6" class="pt-0">
          <h1 class="form-title margin-auto">
            {{ engineerArr.full_name }}
            <v-chip
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3 mr-2"
              style="font-size: 21px !important; font-weight: 700"
              >{{ engineerArr.barcode }}</v-chip
            >
            <Status
              style="margin: 0 !important"
              :status="
                lodash.isEmpty(engineerArr) === false
                  ? engineerArr.activated
                  : false
              "
            ></Status>
            <template
              v-if="engineerArr && engineerArr.role && engineerArr.role.id == 2"
            >
              <v-chip
                label
                outlined
                text-color=""
                dense
                class="ml-2"
                color="blue"
                >Technician</v-chip
              >
            </template>
            <template v-if="engineerArr && engineerArr.is_driver">
              <v-chip
                label
                outlined
                text-color=""
                dense
                class="ml-2"
                color="red"
              >
                Driver</v-chip
              >
            </template>
          </h1>
        </v-col>

        <v-col cols="6" class="pt-0 text-right">
          <template v-if="getPermission('engineer:update')">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :to="
                getDefaultRoute('engineer.update', {
                  param: { id: engineerArr ? engineerArr.id : 0 },
                })
              "
            >
              <v-icon left>mdi-pencil</v-icon>
              Edit
            </v-btn>
          </template>
          <v-btn
            v-on:click="goBack"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            <v-icon left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-tabs
      v-model="engineerTab"
      background-color="transparent"
      centered
      color="cyan"
      fixed-tabs
      class="custom-tab-transparent"
    >
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#personal-info'">
        <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
        Basic Info
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#siganture'">
        <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
        Signature
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" href="#delivery">
        <v-icon color="grey darken-4" left>mdi-truck</v-icon>
        Delivery Summary
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" href="#deliveries">
        <v-icon color="grey darken-4" left>mdi-truck</v-icon>
        <span style="text-transform: none !important">ePOD</span>/ Deliveries
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" href="#visits">
        <v-icon color="grey darken-4" left>mdi-notebook-check</v-icon>
        Visits Summary
      </v-tab>
      <v-tab
        class="font-size-16 font-weight-600 px-2"
        :href="'#reset-password'"
      >
        <v-icon color="grey darken-4" left>mdi-key</v-icon>
        Reset Password
      </v-tab>
      <v-tab
        class="font-size-16 font-weight-600 px-2"
        :href="'#leave-update'"
        v-if="false"
      >
        <v-icon color="grey darken-4" left>mdi-key</v-icon>
        Leave Update
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#file'">
        <v-icon color="grey darken-4" left>mdi-file-multiple</v-icon>
        Additional Document
      </v-tab>
    </v-tabs>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 90vh; position: relative"
    >
      <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
        <v-tab-item :value="'personal-info'">
          <v-row class="justify-center">
            <v-expansion-panels v-model="panel" inset>
              <v-expansion-panel>
                <v-expansion-panel-header class=""
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Personal Info
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <PersonalInfo :engineer="engineerArr"></PersonalInfo>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="
                  engineerArr.allow_leave &&
                  engineerArr.leaves &&
                  engineerArr.leaves.length > 0
                "
              >
                <v-expansion-panel-header class=""
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Leaves
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <table width="100%">
                    <tr>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Leave
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Used
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Allowed
                      </td>
                    </tr>
                    <tr
                      v-for="(leave, index) in engineerArr.leaves"
                      :key="index"
                    >
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_title }}
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_used }}
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_allowed }}
                      </td>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Emergency Contact
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <EmergencyContact :engineer="engineerArr"></EmergencyContact>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Address
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <EngineerAddress :engineer="engineerArr"></EngineerAddress>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-expansion-panel>
                  <v-expansion-panel-header
                    ><h2 class="margin-auto color-custom-blue font-weight-600">
                      Color
                    </h2></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <Color :engineer="engineerArr"></Color>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->
            </v-expansion-panels>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'siganture'">
          <br />
          <v-container class="ml-4">
            <v-layout :key="attachment_url" style="max-width: 50%">
              <v-flex md6>
                <v-img
                  contain
                  v-if="attachment_url"
                  :lazy-src="$defaultImage"
                  :src="attachment_url"
                ></v-img>
                <v-img
                  v-else
                  contain
                  :lazy-src="$defaultImage"
                  :src="$defaultImage"
                ></v-img>
                <br />
                <v-btn
                  v-on:click="select_file()"
                  color="cyan white--text"
                  class="custom-bold-button"
                  label
                  >Change</v-btn
                >
                <v-file-input
                  ref="select-file"
                  class="d-none"
                  accept="image/png, image/jpeg, image/jpg"
                  v-model="signature"
                  dense
                  v-on:change="on_file_change($event)"
                  hide-details
                ></v-file-input>
              </v-flex>
              <v-flex md6> </v-flex>
            </v-layout>
            <br />
            <v-layout
              class="signature-pad"
              id="signature-pad"
              style="max-width: 50%"
            >
              <div
                ref="engineer_signature_div"
                style="width: 280px"
                class="custom-border-grey-dashed signature-pad-wrap"
              >
                <canvas
                  ref="engineer_signature"
                  style="width: 280px; height: 120px !important"
                ></canvas>
              </div>
              <hr />
              <div style="position: relative">
                <v-btn
                  style="position: absolute; bottom: 0px; right: -14px"
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  v-on:click="customer_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div> </v-layout
            ><br />
            <v-layout style="max-width: 50%">
              <v-btn
                v-on:click="submitSignature"
                color="cyan white--text"
                class="custom-bold-button"
                :disabled="valid_signature"
                >Submit</v-btn
              >
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item :value="'emergency-contact'">
          <EmergencyContact :engineer="engineerArr"></EmergencyContact>
        </v-tab-item>
        <v-tab-item :value="'engineer-address'">
          <EngineerAddress :engineer="engineerArr"></EngineerAddress>
        </v-tab-item>
        <v-tab-item :value="'color'">
          <Color :engineer="engineerArr"></Color>
        </v-tab-item>
        <v-tab-item value="delivery">
          <DeliveryListingTemplate
            is-admin
            visit-type="all"
            internal
            :visit-status="0"
            detailRoute="delivery.detail"
            :engineer-id="engineerArr.id"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="deliveries">
          <DeliveriesTabByDriver
            :parent-detail="engineerArr"
          ></DeliveriesTabByDriver>
        </v-tab-item>
        <v-tab-item value="visits">
          <VisitListingTemplate
            is-admin
            visit-type="all"
            internal
            :visit-status="0"
            detailRoute="visit.detail"
            :engineer-id="engineerArr.id"
          ></VisitListingTemplate>
        </v-tab-item>

        <v-tab-item v-if="getPermission('job:view')" :value="'job-summary'">
          <Tickets
            :detail="engineerArr"
            :type_id="engineerArr.id"
            type="engineer"
          ></Tickets>
        </v-tab-item>
        <v-tab-item :value="'reset-password'">
          <PasswordUpdate
            v-on:password:updated="userTab = 'tab-history'"
          ></PasswordUpdate>
        </v-tab-item>
        <v-tab-item :value="'leave-update'">
          <LeaveUpdateEngineer
            :detail="engineerArr"
            :engineer-id="engineerArr.id"
          ></LeaveUpdateEngineer>
        </v-tab-item>
        <v-tab-item :value="'file'">
          <AdditionalDocuments
            :type-id="engineerArr.id"
            class="mx-4"
            type-text="Technicians"
            :type="29"
            :extensive="false"
          ></AdditionalDocuments>
        </v-tab-item>
      </v-tabs-items>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import Delete from "@/view/pages/partials/Delete.vue";
import PersonalInfo from "@/view/pages/engineer/detail/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/detail/Emergency-Contact";
import EngineerAddress from "@/view/pages/engineer/detail/Engineer-Address";
//import Color from "@/view/pages/engineer/detail/Color";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";
import { PATCH, UPLOAD, PUT } from "@/core/services/store/request.module";
import ObjectPath from "object-path";
import DeliveriesTabByDriver from "@/view/pages/partials/Deliveries-Tab-By-Driver";

import Status from "@/view/pages/partials/Status.vue";
import PasswordUpdate from "@/view/pages/engineer/PasswordUpdate";
import LeaveUpdateEngineer from "@/view/pages/engineer/Leave-Update-Engineer";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
// import Tickets from "@/view/pages/engineer/Tickets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import DeliveryListingTemplate from "@/view/pages/delivery/Visit-Listing-Template";
import SignaturePad from "signature_pad";
export default {
  mixins: [CommonMixin, ValidationMixin, EngineerMixin],
  name: "engineer-detail",
  title: "Engineer",
  data() {
    return {
      detail: new Object(),
      engineer: 0,
      panel: 0,
      engineerTab: null,
      pageLoading: true,
      customer_signature: null,
      attachment_url: null,
      signature: null,
      deleteDialog: false,
      engineerArr: {},
    };
  },
  components: {
    Tickets,
    Status,
    VisitListingTemplate,
    DeliveryListingTemplate,
    PersonalInfo,
    EmergencyContact,
    EngineerAddress,
    //Color,
    PasswordUpdate,
    DeliveriesTabByDriver,
    LeaveUpdateEngineer,
    AdditionalDocuments,
  },
  watch: {
    engineerTab(param) {
      if (param == "siganture") {
        let _this = this;
        setTimeout(function () {
          _this.initSignature();
        }, 500);
      }
    },
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.engineerArr.user.id}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(PUT, {
          url: `user/${this.engineerArr.user.id}/signature/update`,
          data: {
            customer: _this.customer_signature.toDataURL(),
          },
        })
        .then((data) => {
          this.attachment_url = data?.data?.url;
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          // _this.loadContent();
          _this.customer_signature.clear();
        });
    },
    loadContent() {
      this.attachment_url = this.engineerArr?.user?.signature ?? null;
    },
    initSignature() {
      const _this = this;
      if (_this.engineerTab == "siganture") {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["engineer_signature"];
          let ccanvasDiv = _this.$refs["engineer_signature_div"];
          // let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = 280; //cparentDiv.offsetWidth / 2;

          ccanvas.setAttribute("width", cparentWidth);

          ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");

          _this.customer_signature = new SignaturePad(ccanvas);
        });
      }
    },
    deleteEngineer() {
      const _this = this;
      if (!_this.$refs.engineerDeleteForm.validate()) {
        return false;
      }
    },
  },
  mounted() {
    const _this = this;
    _this
      .getEngineer()
      .then((response) => {
        _this.engineerArr = response;
        _this.loadContent();
        _this.initSignature();
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Technician", route: "engineer" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });

    /*  _this.loadContent(); */
  },
  created() {
    const _this = this;
    _this.engineer = _this.$route.params.id;
    if (!_this.engineer || _this.engineer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
    getTitle() {
      let result = [];
      if (this.engineerArr && this.engineerArr.customer) {
        result.push(this.engineerArr.customer.display_name);
      }
      if (
        this.engineerArr &&
        this.engineerArr.customer &&
        this.engineerArr.customer.default_person
      ) {
        result.push(this.engineerArr.customer.default_person.display_name);
      }
      if (this.engineerArr && this.engineerArr.barcode) {
        result.push(this.engineerArr.barcode);
      }
      return result.join(" • ");
    },
    countryName() {
      let result = "";
      if (this.lodash.isEmpty(this.engineerArr.country) === false) {
        result = this.engineerArr.country.name;
      }
      return result;
    },
    getStatus() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.activated
        : false;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.barcode
        : false;
    },
    getRouteParam() {
      let result = { customer: 0, id: 0 };
      if (this.lodash.isEmpty(this.engineerArr) === false) {
        if (this.engineerArr.id) {
          result.id = this.engineerArr.id;
        }
        if (this.engineerArr.customer) {
          result.customer = this.engineerArr.customer.id;
        }
      }
      return result;
    },
  },
};
</script>
